<template>
  <div class="admin-view-container policy-view" v-loading="tableLoading">
    <div class="admin-table-search">
      <el-form
        ref="queryForm"
        :model="tableQuery"
        label-position="right"
        size="medium"
        @submit.native.prevent="search"
        class="admin-tablequery">
        <el-form-item label="流水号" prop="compensateAuditNo" style="width: 18%">
          <el-input v-model="tableQuery.compensateAuditNo" clearable></el-input>
        </el-form-item>
        <el-form-item label="补偿状态" prop="compensateAuditStatus" style="width: 18%">
          <el-select v-model="tableQuery.compensateAuditStatus" placeholder="全部" clearable>
            <el-option value="" label="全部"></el-option>
            <el-option value="1" label="待打款"></el-option>
            <el-option value="2" label="已打款"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提交日期" style="width: 25%">
          <el-date-picker
            v-model="pickDateTime"
            @change="dataPickerHandler"
            :default-time="['00:00:00', '23:59:59']"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left: auto; text-align: right">
          <el-button size="medium" type="primary" :loading="tableLoading" native-type="submit" class="admin-btn">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="admin-table-list" v-loading="tableLoading">
      <el-table :data="tableData.data" border height="100%">
        <el-table-column label="流水号" prop="compensateAuditNo">
          <template slot-scope="scope">
            {{ scope.row.compensateAuditNo }}
            <i class="el-icon-warning" v-if="scope.row.compensateAuditStatus == 1" style="color: #ff422d"></i>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="creationTime" :formatter="$formatter.date(true)"></el-table-column>
        <el-table-column label="补偿数量" prop="compensateNum"></el-table-column>
        <el-table-column label="结算金额(元)" prop="compensateTotalAmount"></el-table-column>
        <el-table-column label="补偿状态" prop="compensateAuditStatus">
          <template slot-scope="scope">
            <span :style="scope.row.compensateAuditStatus === 1 ? 'color: #ff422d' : 'color: #34b377'">
              {{ $dict.getData($dict.compensateAuditStatus, scope.row.compensateAuditStatus).label }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link type="primary" @click="detailHandler(scope.row)">查看明细</el-link>
          </template>
        </el-table-column>
      </el-table>

      <div class="admin-pager-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tableQuery.page"
          :page-size="tableQuery.size"
          :total="tableData.total"
          :layout="tableConfig.layout"
          background></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {schReconciliationGetList} from '@/api/index';
import tableMinx from '@/mixin/table-page';
export default {
  mixins: [tableMinx(schReconciliationGetList)],
  data() {
    return {
      loading: false,
      queryType: '1',
      queryVal: '',
      pickDateTime: [],
      tableQuery: {
        compensateAuditNo: '',
        compensateAuditStatus: '',
        startTime: '',
        endTime: '',
      },
    };
  },
  created() {},
  methods: {
    // 日期选中
    dataPickerHandler() {
      if (this.pickDateTime) {
        this.tableQuery.startTime = Date.parse(new Date(this.pickDateTime[0])) / 1000;
        this.tableQuery.endTime = Date.parse(new Date(this.pickDateTime[1])) / 1000;
      } else {
        this.tableQuery.startTime = '';
        this.tableQuery.endTime = '';
      }
    },

    async detailHandler(row) {
      let vNode = this.$createElement((await import('./detail.vue')).default, {
        key: Math.random(),
        props: {
          row: row,
        },
      });

      this.$adminDialog(vNode, {
        title: '会员服务补偿列表',
        width: '1500px',
      });
    },

    mixinParseData(data) {
      //mixin调用该方法 传入res.data.data数据 处理后返回data
      return data;
    },
  },
};
</script>

<style lang="scss">
// .policy-view {
// }
</style>
